.header {
    @apply px-[30px] py-[15px] lg:px-[60px] md:py-[30px] bg-[#1a1a1a];
    @apply relative h-fit w-full top-0 left-0 z-[1];

    padding: 15px 30px;
    background-color: #1a1a1a;
    z-index: 1;

    @media (min-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media(min-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
    }
    

    .mobile-nav .mobile-nav__bar,
    .desktop-nav {
        @apply justify-between items-center;
    }

    .mobile-nav .mobile-nav__bar {
        @apply flex z-10 relative w-full lg:hidden;
        
        .logo {
            @apply w-[60px] h-[60px];

            width: 60px;
            height: 60px;
        }
    
        .menu-btn {
            @apply w-[35px] h-[35px] cursor-pointer;

            &__slice {
                @apply w-full h-[1px] bg-white my-[10px] m-auto;
                @apply transition-all duration-300 ease-in-out;

                height: 1px;
                margin: 10px 0;
            }

            &.is-open .menu-btn__slice:nth-child(1) {
                @apply transform rotate-45 origin-center translate-y-[5.5px];

                transform: translateY(5.5px);
            }

            &.is-open .menu-btn__slice:nth-child(2) {
                @apply transform rotate-[-45deg] translate-y-[-5.5px] origin-center;

                transform: rotate(-45deg) translateY(-5.5px);
            }
        }
    }

    .mobile-nav__menu {
        @apply block lg:hidden fixed top-0 left-0 w-full h-full bg-[#1a1a1a] z-[2];
        @apply p-8 translate-y-[-100%] transition-all duration-700 ease-in-out;

        background-color: #1a1a1a;
        transform: translateY(-100%);

        ul {
            @apply opacity-0 transition-opacity delay-300 ease-in-out duration-300;
        }

        ul li {
            @apply mb-8 w-full;

            a {
                @apply text-white;
                @apply text-4xl;
            }
        }

        &.is-open {
            @apply translate-y-[90px];

            transform: translateY(90px);

            ul {
                @apply opacity-100;
            }
        }
    }

    .desktop-nav {
        @apply w-full flex;
        
        .logo {
            @apply w-[60px] h-[60px] md:w-[81px] md:h-[81px];

            width: 60px;
            height: 60px;

            @media (min-width: 768px) {
                width: 81px;
                height: 81px;
            }

        }

        .nav-menu {
            @apply w-fit;
        }

        .nav-menu ul li {
            @apply mb-2;
        }

        .nav-menu ul li a {
            @apply text-white text-base flex relative items-center justify-between transition-[margin-left] duration-300 ml-0;

            transition-property: margin-left;
            
            &:after {
                @apply content-[''] block h-[1px] w-0 ml-2 bg-white transition-[width] duration-300 ease-in-out absolute right-[-30px]; 

                content: '';
                transition-property: width;
                right: -30px;
            }

            &:hover {
                @apply ml-[-20px];

                margin-left: -20px;

                &:after {
                    @apply w-[30px];

                    width: 30px;
                }
            }
        }
    }
}
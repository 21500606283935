.footer {
    @apply p-[30px] sm:px-[60px] sm:py-[30px];
    @apply text-white;

    padding: 30px;

    @media(min-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
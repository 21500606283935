.section {
    @apply p-[30px] lg:px-[60px] lg:py-[30px];

    padding: 30px;

    @media(min-width: 1024px) {
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__heading {
        @apply py-[60px];  

        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__heading,
    .card-intro {
        span.title {
            @apply uppercase text-white text-sm tracking-widest block mb-2;
        }

        h2.headline {
            @apply text-white text-4xl;
        }
    }
}

.section-card {
    &__wrapper {
        @apply grid grid-cols-1 md:grid-cols-2 items-start py-[60px];

        padding-top: 60px;
        padding-bottom: 60px;
    }

    .card-intro h2.headline {
        @apply w-3/4 mb-6;
    }

    .card-body {
        @apply text-white;

        p {
            @apply mb-6;
        }

        ul {
            @apply lg:flex;

            li {
                @apply mb-4 md:mr-4;
            }

            li a {
                @apply uppercase text-[14px] tracking-widest w-fit relative;

                &:before {
                    @apply block absolute h-[1px] w-full lg:w-0 bg-white bottom-[-5px] right-0; 
                    @apply ease-in-out duration-300;
                    content: '';
                    height: 1px;
                    bottom: -5px;
                }

                &:hover:before {
                    @apply w-full left-0;
                }
            }
        }
    }
}
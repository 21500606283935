.btn {
    @apply relative w-fit overflow-hidden z-0 py-10 px-6 rounded-[100%] scale-[0.9] lg:scale-100;
    
    border-radius: 100%;
    transform: scale(0.9);
    
    
    &:after {
        content: '';
        @apply block absolute bg-white w-full h-full rounded-[100%] top-0 left-0 z-0 ease-in-out duration-300 scale-100 lg:scale-0;

        border-radius: 100%;
    }
    
    span {
        @apply text-center block relative z-[1] text-[#1a1a1a] lg:text-white ease-in-out duration-300;

        z-index: 1;
        color: #1a1a1a;

        @media(min-width: 1024px) {
            color: #fff;
        }
    }

    a {
        @apply block absolute w-full h-full top-0 left-0 z-[1];

        z-index: 1;
    }

    &:hover {
        &:after {
            @apply lg:scale-100;
        } 

        span {
            @apply text-[#1a1a1a];
            
            color: #1a1a1a;
        }
    }
}
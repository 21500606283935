.scroll-container {
  @apply fixed top-0 left-0 w-full overflow-hidden will-change-transform;
}

.scroll-sizer {
  @apply z-[-1];

  z-index: 1;
}

.container {
  @apply max-w-[1280px] m-[auto];

  max-width: 1280px;
  margin: auto;
}

.page {
  @apply relative top-0 z-0;
}

.list {
  .item {
    @apply block mb-12 lg:mb-20 md:flex md:justify-between;

    &__col {
      @apply w-auto md:w-full mb-4 md:px-4; 

      &:first-child {
        @apply pl-0;
      }

      &:nth-child(3) {
        @apply w-1/2;
      }

      .btn {
        @apply block ml-[-10px] md:ml-auto lg:text-center;

        margin-left: -10px;

        @media(min-width: 768px) {
          margin-left: auto;
        }
      }
    }
    
    &__label {
      @apply text-sm text-gray-500 block mb-0 capitalize;
    }

    &__title {
      @apply text-white text-[1.75em] mb-2;

      font-size: 1.75em;
    }

    &__desc {
      @apply text-white;
    }
  }
}
